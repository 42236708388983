import { useHover } from "@react-native-aria/interactions";
import { useEffect, useRef, useState } from "react";
import { View, Text, TouchableOpacity, Image, Animated, ScrollView, useWindowDimensions } from "react-native";

const TopBar = ({ offset, pos1, pos2, pos3, scrollRef }) => {

  const { width , height} = useWindowDimensions();
  const cond = width < 900;

  const menu = [
    "Accueil",
    "Menu",
    "Contact"
  ];

  useEffect(() => {
    const off = offset + height / 3;

    if (off > pos1 && off < pos2) {
      setCurrentIndex(0);
    } else if (off > pos2 && off < pos3) {
      setCurrentIndex(1);
    } else if (off > pos3) {
      setCurrentIndex(2);
    }

    // setCurrentIndex(parseInt(off / height))

  }, [offset, pos1, pos2, pos3])

  const [currentIndex, setCurrentIndex] = useState(0);

  const positions = [pos1, pos2, pos3];

  return (
    <View style={{ flexDirection: "row", width: "100vw", paddingHorizontal: 20, alignItems: "center", position: "fixed", top: 0, left: 0, zIndex: 1, backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
      <Image source={require("./assets/logo.png")} style={{ height: cond ? 70 : 100, width: cond ? 70 * 193 / 132 : 100 * 193 / 132 }} resizeMode="contain" />
      <View style={{ width: "100vw", position: "absolute", justifyContent: "center", flexDirection: "row" }}>
        {
          menu.map((el, i) => {

            const opacityAnim = useRef(new Animated.Value(0)).current;

            if (i == currentIndex) {
              Animated.spring(opacityAnim, {
                speed: 5,
                bounciness: 5,
                toValue: 1,
                useNativeDriver: true
              }).start()
            } else {
              Animated.spring(opacityAnim, {
                speed: 5,
                bounciness: 5,
                toValue: 0,
                useNativeDriver: true
              }).start()
            }

            return (
              <TouchableOpacity
                key={i}
                onPress={() => {
                  scrollRef.current.scrollTo(positions[i]);
                }}
                style={{
                  alignItems: "center",
                  marginHorizontal: i == 1 ? 20 : 0,
                }}
              >
                <Text style={{ fontSize: 20, fontWeight: "600" }}>{el}</Text>
                <Animated.View style={{ height: 10, width: 10, borderRadius: 10, opacity: opacityAnim, backgroundColor: "black", marginTop: 4 }} />
              </TouchableOpacity>
            )
          })
        }
      </View>
    </View>
  )
}

const App = () => {

  const [offset, setOffset] = useState(0);
  const [pos1, setPos1] = useState(0);
  const [pos2, setPos2] = useState(0);
  const [pos3, setPos3] = useState(0);

  const scrollRef = useRef(0);

  const menus = [
    ["Menu des Félins", "Tous les jours, midi et soir", "Une entrée, un plat et un dessert", [["Cuisses de grenouilles, fondue de poireaux", "Velouté de légumes de saison ferme du pré Mazet"], ["Tartare de boeuf Charolais, frites maison", "Suprême de poulet fermier, fondue de poivrons et tomates"], ["Mi-cuit au chocolat et crême anglaise coco maison", "Glace 3 boules"]]],
    ["Menu des Félins", "Tous les jours, midi et soir", "Une entrée, un plat et un dessert", [["Cuisses de grenouilles, fondue de poireaux", "Velouté de légumes de saison ferme du pré Mazet"], ["Tartare de boeuf Charolais, frites maison", "Suprême de poulet fermier, fondue de poivrons et tomates"], ["Mi-cuit au chocolat et crême anglaise coco maison", "Glace 3 boules"]]],
  ]

  const ref = useRef()
  const { isHovered, hoverProps } = useHover({}, ref);

  const [index, setIndex] = useState(0);

  const images = [
    require("./assets/terasse.jpeg"),
    require("./assets/interieur.jpeg"),
    require("./assets/interieur2.jpeg"),
  ]

  const { width } = useWindowDimensions();
  const cond = width < 900;

  return (
    <View style={{ height: "100vh" }}>
      <TopBar offset={offset} pos1={pos1} pos2={pos2} pos3={pos3} scrollRef={scrollRef} />
      <ScrollView ref={scrollRef} scrollEventThrottle={16} onScroll={(e) => setOffset(e.nativeEvent.contentOffset.y)} style={{ height: "100vh" }}>

        <View onLayout={(e) => setPos1(e.nativeEvent.layout.y)} style={{ height: "100vh", width: "100vw", flexDirection: "row" }} >
          <View style={{ width: "50vw", alignItems: "center", justifyContent: "space-around", padding: 20 }}>
            <Image style={{ width: "30vw", height: "calc(30vw * 193 / 132 )" }} source={require("./assets/logo.png")} resizeMode="contain" />
            {/* <ReserveButton /> */}
          </View>
          <View ref={ref} style={{ width: "50vw", justifyContent: "center", alignItems: "center", padding: 20 }} {...hoverProps}>
            {
              images.map((el, i) => {

                return (
                  <View key={i}>
                    {index == i ? 
                    
                      <Image style={{ height: "100vh", width: "50vw", borderBottomLeftRadius: 10, zIndex: 1 }} resizeMode="cover" source={el} />
                      : 
                      null
                    }
                  </View>
                  )
              })
            }
            {
              isHovered ? 
              <View style={{ flexDirection: "row", position: "absolute", zIndex: 1, bottom: 20, alignSelf: "center", padding: 7, borderRadius: 10, backgroundColor: "rgba(255, 255, 255, 0.7)" }} >
                <TouchableOpacity onPress={() => setIndex(0)} style={{ height: 10, width: 10, borderRadius: 10, backgroundColor: index == 0 ? "black" : "gray" }} />
                <TouchableOpacity onPress={() => setIndex(1)} style={{ height: 10, width: 10, borderRadius: 10, backgroundColor: index == 1 ? "black" : "gray",  marginHorizontal: 10 }} />
                <TouchableOpacity onPress={() => setIndex(2)} style={{ height: 10, width: 10, borderRadius: 10, backgroundColor: index == 2 ? "black" : "gray" }} />
              </View>
              :
              null
            }
          </View>
        </View>


        <View onLayout={(e) => {
          setPos2(e.nativeEvent.layout.y);
        }} style={{ width: "100vw", minHeight: `calc(100vh)` }} >
          <Text style={{ textAlign: "center", fontWeight: "700", fontSize: cond ? 50 : 70, marginBottom: 30, marginTop: 20 }}>Menus</Text>
          <View style={{ flexDirection: cond ? "column" : "row", width: "100vw", justifyContent: "center", alignItems: cond ? "center" : null }}>
            {
              menus.map((el, i) => {

                const [w, setW] = useState(0);
                const [mainW, setMainW] = useState(0);

                const fontSize = 26;

                return (
                  <View onLayout={(e) => {
                    if (mainW == 0) {
                      setMainW(e.nativeEvent.layout.width)
                    }
                  }} key={i} style={{ borderColor: "black", borderWidth: 1, margin: 10, borderRadius: 10, padding: 10, paddingTop: 0, borderTopColor: "transparent", marginTop: cond ? i != 0 ? 40 : 0 : 0}}>
                    <View style={{ flexDirection: "row", alignItems: "center", transform: [{ translateY: -fontSize / 2 }] }}>
                      <View style={{ width: cond ? 30 : 20, height: 1, backgroundColor: "black", transform: [{ translateX: -8 }] }} />
                      <Text onLayout={(e) => setW(e.nativeEvent.layout.width)} style={{ marginHorizontal: 5, fontWeight: "600", fontSize: fontSize }}>{el[0]}</Text>
                      <View style={{ width: cond ? mainW - 40 - w : mainW - 20 - w, height: 1, backgroundColor: "black", transform: [{ translateX: 8 }] }} />
                    </View>

                    <Text>{el[1]}</Text>
                    <Text>{el[2]}</Text>
                    <Text style={{ marginTop: 10, marginBottom: 6, fontSize: 18, fontWeight: "600", textAlign: "center" }}>Entrées</Text>
                    {
                      el[3][0].map((entree, j) => {
                        return (
                          <Text key={j}>{entree}</Text>
                        )
                      })
                    }

                    <Text style={{ marginTop: 10, marginBottom: 6, fontSize: 18, fontWeight: "600", textAlign: "center" }}>Plats</Text>
                    {
                      el[3][0].map((plat, j) => {
                        return (
                          <Text key={j}>{plat}</Text>
                        )
                      })
                    }

                    <Text style={{ marginTop: 10, marginBottom: 6, fontSize: 18, fontWeight: "600", textAlign: "center" }}>Desserts</Text>
                    {
                      el[3][0].map((dessert, j) => {
                        return (
                          <Text key={j}>{dessert}</Text>
                        )
                      })
                    }
                  </View>
                )
              })
            }
          </View>
          <Text style={{ textAlign: "center", fontWeight: "700", fontSize: cond ? 50 : 70, marginVertical: 30 }}>Carte</Text>
          <View style={{ width: "100vw", alignItems: "center" }}>
            <View>
              <View style={{
                width: cond ? "calc(100vw - 40px)" : 400,
                maxWidth: 400,
                height: 0,
                borderBottomWidth: 66,
                borderBottomColor: 'black',
                borderLeftWidth: 40,
                borderLeftColor: 'transparent',
                borderRightWidth: 40,
                borderRightColor: 'transparent',
                borderStyle: 'solid',
                alignItems: "center"
              }}>
                <Text style={{ fontSize: 26, fontWeight: "600", color: "white", transform: [{ translateY: 20 }] }}>A partager</Text>
                <View style={{ borderColor: "black", borderWidth: 1, width: cond ? "calc(100vw - 40px - 2px)" : 398, maxWidth: 398, marginTop: 33, borderTopColor: "transparent", padding: 10 }} >
                  <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <Text>Planche de cochonailles</Text>
                    <Text>17€</Text>
                  </View>
                  <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 10 }}>
                    <View>
                      <Text>Les concerves de Guildive (au choix)</Text>
                      <Text>- Navajs (couteaux)</Text>
                      <Text>- Chipirons à l'encre</Text>
                      <Text>- Ventrèche de thon Albacore</Text>
                      <Text>- Moules sauce vieira</Text>
                    </View>
                    <Text>15€</Text>
                  </View>
                  <View style={{ width: 380, height: 1, backgroundColor: "black", marginVertical: 10 }} />
                  <Text style={{ fontSize: 26, fontWeight: "600" }}>Pour accompagner</Text>
                  <View style={{ flexDirection: "row", justifyContent: "space-between", marginTop: 10 }}>
                    <Text>Champagne Eric Collinet Extra Brut "Temporis"</Text>
                    <Text>8€</Text>
                  </View>
                  <View style={{ flexDirection: "row", justifyContent: "space-between", marginVertical: 10 }}>
                    <Text>Bourgogne Tonnerre (Moutard) 2020</Text>
                    <Text>5€</Text>
                  </View>
                  <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                    <Text>Magra Bendi de Lusigny-sur-Barse</Text>
                    <Text>5€</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={{ alignItems: "center", width: "100vw", marginTop: cond ? 250 : 220 }} >
          {/* liste entrée, plats et desserts */}
          <View style={{ width: cond ? "calc(100vw - 40px)" : 800 }} >
            <Text style={{ fontWeight: "700", fontSize: cond ? 50 : 70, marginVertical: 30, alignSelf: "center" }}>Entrées</Text>
            <View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Tartare de boeuf Charolais, <Text style={{ fontSize: 18, fontWeight: "400" }}>salade verte</Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>12€</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Tartare de boeuf Charolais, <Text style={{ fontSize: 18, fontWeight: "400" }}>salade verte</Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>12€</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Tartare de boeuf Charolais, <Text style={{ fontSize: 18, fontWeight: "400" }}>salade verte</Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>12€</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Tartare de boeuf Charolais, <Text style={{ fontSize: 18, fontWeight: "400" }}>salade verte</Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>12€</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Tartare de boeuf Charolais, <Text style={{ fontSize: 18, fontWeight: "400" }}>salade verte</Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>12€</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Tartare de boeuf Charolais, <Text style={{ fontSize: 18, fontWeight: "400" }}>salade verte</Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>12€</Text>
              </View>
            </View>
            <Text style={{ fontWeight: "700", fontSize: cond ? 50 : 70, marginVertical: 30, alignSelf: "center" }}>Plats</Text>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Andouillette AAAAA de Jully-sur-Sarce (200g), <Text style={{ fontSize: 18, fontWeight: "400" }}>sauce au Chaource fermier, chutney d'oignons rouges, frites maison</Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>20€</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Andouillette AAAAA de Jully-sur-Sarce (200g), <Text style={{ fontSize: 18, fontWeight: "400" }}>sauce au Chaource fermier, chutney d'oignons rouges, frites maison</Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>20€</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Andouillette AAAAA de Jully-sur-Sarce (200g), <Text style={{ fontSize: 18, fontWeight: "400" }}>sauce au Chaource fermier, chutney d'oignons rouges, frites maison</Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>20€</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Andouillette AAAAA de Jully-sur-Sarce (200g), <Text style={{ fontSize: 18, fontWeight: "400" }}>sauce au Chaource fermier, chutney d'oignons rouges, frites maison</Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>20€</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Andouillette AAAAA de Jully-sur-Sarce (200g), <Text style={{ fontSize: 18, fontWeight: "400" }}>sauce au Chaource fermier, chutney d'oignons rouges, frites maison</Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>20€</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Andouillette AAAAA de Jully-sur-Sarce (200g), <Text style={{ fontSize: 18, fontWeight: "400" }}>sauce au Chaource fermier, chutney d'oignons rouges, frites maison</Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>20€</Text>
              </View>
            <Text style={{ fontWeight: "700", fontSize: cond ? 50 : 70, marginVertical: 30, alignSelf: "center" }}>Desserts et Fromages</Text>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Assiette de fromages au lait cru (Soumaintrain, Compté, Chaource), <Text style={{ fontSize: 18, fontWeight: "400" }}></Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>10€</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Assiette de fromages au lait cru (Soumaintrain, Compté, Chaource), <Text style={{ fontSize: 18, fontWeight: "400" }}></Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>10€</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Assiette de fromages au lait cru (Soumaintrain, Compté, Chaource), <Text style={{ fontSize: 18, fontWeight: "400" }}></Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>10€</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Assiette de fromages au lait cru (Soumaintrain, Compté, Chaource), <Text style={{ fontSize: 18, fontWeight: "400" }}></Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>10€</Text>
              </View>
              <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>Assiette de fromages au lait cru (Soumaintrain, Compté, Chaource), <Text style={{ fontSize: 18, fontWeight: "400" }}></Text></Text>
                <Text style={{ fontWeight: "600", fontSize: 18 }}>10€</Text>
              </View>
          </View>
        </View>


        <View onLayout={(e) => setPos3(e.nativeEvent.layout.y)} style={{ height: "100vh", width: "100vw", justifyContent: "center", alignItems: "center" }} >
          <Text style={{ fontSize: 70, fontWeight: "700" }} >Contact</Text>
        </View>

      </ScrollView>
    </View>
  )
}

export default App;